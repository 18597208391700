import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  Drawer,
  FormControlLabel,
  Grid,
  Hidden,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { ExperiedAgreements, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  GETAGREEMENTBYCONTACTID as query_2,
  GETAGREEMENTDOCFROMASSETBYAGREEMENTID as query_4,
  GETCONTACTFROMUSERPROFILEBYUSERID as query_1,
  GETPROPERTYBYUNITNAME as query_5,
  GETUNITSFOREACHAGREEMENTFROMAGREEMENTUNITBYAGREEMENTID as query_3
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  experiedroot: {
    padding: "0px 16px",
    backgroundColor: "#F5F7FA",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filterdata: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    [theme.breakpoints.up("sm")]: {
      minHeight: "68vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "75vh",
    },
  },
  active: {
    fontSize: "0.75rem",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
  },
  drawer: {
    "& .MuiPaper-root-MuiDrawer-paper": {
      // "background-color": "transparent",
      // "box-shadow": "none"
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
}));
const ExperiedAgreement = (props) => {
  const user_id = localStorage?.getItem(LocalStorageKeys?.user_id);
  const { t=()=>false } = props
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const [drawer, setDrawer] = React.useState(false);
  const [filterChild, setfilterChild] = React.useState([]);
  const [filterActive, setfilterActive] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [agreementList, setAgreementList] = React.useState([]);
  const [unitList, setUnitList] = React.useState([]);
  const [propertyList, setPropertyList] = React.useState([]);
  const [assetList, setAssetList] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const filterData = [
    {
      id: "1",
      title: "Request Type",
      values: [
        {
          label: "maintenance",
          value: true,
        },
        {
          label: "general",
          value: false,
        },
      ],
    },
    {
      id: "2",

      title: "Agreement",
      values: [
        {
          label: "AGR-125",
          value: false,
        },
        {
          label: "AGR-126",
          value: true,
        },
        {
          label: "AGR-127",
          value: false,
        },
        {
          label: "AGR-128",
          value: true,
        },
      ],
    },
    {
      id: "3",

      title: "Units",
      values: [
        {
          label: "K-123-125",
          value: false,
        },
        {
          label: "K-123-126",
          value: true,
        },
        {
          label: "K-123-127",
          value: false,
        },
        {
          label: "K-123-128",
          value: true,
        },
      ],
    },
    {
      id: "4",

      title: "Status",
      values: [
        {
          label: "New",
          value: false,
        },
        {
          label: "In-Progress",
          value: true,
        },
        {
          label: "Resolved",
          value: false,
        },
      ],
    },
  ];

  const drawerclose = () => {
    setDrawer(false);
  };

  const headerChild = (id) => {
    let filerdatavalue = filterData.filter((e) => {
      return e.id === id;
    });
    setfilterChild(filerdatavalue[0].values);
    setfilterActive(id);
  };

  // const cancel = () => {
  //     setDrawer(false)
  // }
  const goBack = () => {
    history.goBack(-1);
  };

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const userId = user_id;
      const payload = {
        query: query_1,
        variables: { userId: userId },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          let tempContactId =
            response?.data?.data?.user_profiles[0]?.contact_id;
          const payload = {
            query: query_2,
            variables: {
              contactId: tempContactId,
              leaseEndDate: moment(Date())
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD"),

              client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              let tempAgreementList = response?.data?.data?.agreement;
              if (tempAgreementList?.length > 0) {
                setAgreementList(tempAgreementList);
                const payload = {
                  query: query_3(tempAgreementList).loc.source.body,
                  variables: {},
                };
                NetworkCall(
                  `${config.api_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
                )
                  .then((response) => {
                    let tempUnitListJson = response?.data?.data;
                    let tempUnitList = Array.from(
                      Object.keys(tempUnitListJson),
                      (k) => tempUnitListJson[k]
                    );
                    setUnitList(tempUnitList);
                    const payload = {
                      query: query_4(tempAgreementList).loc.source.body,
                      variables: {},
                    };
                    NetworkCall(
                      `${config.api_url}`,
                      NetWorkCallMethods.post,
                      payload,
                      null,
                      true,
                      false
                    )
                      .then((response) => {
                        let tempAssetListJson = response?.data?.data;
                        let tempAssetList = Array.from(
                          Object.keys(tempAssetListJson),
                          (k) => tempAssetListJson[k]
                        );
                        setAssetList(tempAssetList);
                        const payload = {
                          query: query_5(tempUnitList).loc.source.body,
                          variables: {},
                        };
                        NetworkCall(
                          `${config.graphql_url}`,
                          NetWorkCallMethods.post,
                          payload,
                          null,
                          true,
                          false
                        )
                          .then((response) => {
                            let tempPropertyListJson = response?.data?.data;
                            let tempPropertyList = Array.from(
                              Object.keys(tempPropertyListJson),
                              (k) => tempPropertyListJson[k]
                            );
                            setPropertyList(tempPropertyList);
                            setLoading(false);
                          })
                          .catch((error) => {
                            alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some thing went wrong"),
                            });
                          });
                      })
                      .catch((error) => {
                        alert.setSnack({
                          ...alert,
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: t("Some thing went wrong"),
                        });
                      });
                  })
                  .catch((error) => {
                    alert.setSnack({
                      ...alert,
                      open: true,
                      severity: AlertProps.severity.error,
                      msg: t("Some thing went wrong"),
                    });
                  });
              } else {
                setLoading(false);
              }
            })
            .catch((error) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some thing went wrong"),
              });
            });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some thing went wrong"),
          });
        });
    } else {
      RetainLocalStorage();
      history.push(Routes.welcome);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Container
            maxWidth="sm"
            className={classes.root}
            style={{ height: size?.height }}
          >
            <Grid container>
              {/* Title Card */}
              <Grid item xs={12}>
                <TitleBar
                  text={t("ExperiedAgreement")}

                  goBack={goBack}
                />
                <div className={classes.experiedroot}>
                  <Box height={"16px"} />
                  <Box display="flex" alignItems="center">
                    {/* Total Agreements */}
                    <Box flexGrow={1}>
                      <Typography className={classes.total}>
                        {agreementList.length}&nbsp;{t("Agreements")}
                      </Typography>
                    </Box>
                    <Box>
                      {/* Filter*/}
                      {/* <IconButton className={classes.filter} onClick={draweropen}>
                        <Badge variant="dot" color="primary">
                          <img src="/images/icons8-funnel.svg" alt="filter" />
                        </Badge>
                      </IconButton> */}
                    </Box>
                  </Box>
                  <Box height={"16px"} />
                  {/* ExperiedAgreements Mapping */}
                  <div
                    className={classes.screenPadding}
                    style={{
                      height: size?.height - (55 + 56),
                      padding: "12px",
                    }}
                  >
                    {agreementList.map((val, index, array) => {
                      return (
                        <ExperiedAgreements
                          t={t}
                          data={val}
                          unit={unitList[index]}
                          asset={assetList[index]}
                          property={propertyList[index]}
                        />
                      );
                    })}
                    <Box height={"16px"} />
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* Filter Drawer Agreements */}
            {/* filter drawer */}
            <Hidden smDown>
              <Dialog
                open={drawer}
                onClose={drawerclose}
                fullWidth={true}
                maxWidth="sm"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TitleBar
                      text={t("ExperiedAgreement")}
                      goBack={drawerclose}
                      clearFilter
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.filterleft}>
                    <div>
                      {filterData.map((e) => {
                        let filerdatavalue = e.values.filter((e) => {
                          return e.value === true;
                        });

                        return (
                          <>
                            <Typography
                              className={
                                e.id === filterActive
                                  ? classes.filterdataActive
                                  : classes.filterdata
                              }
                              key={e.id}
                              whiteSpace="nowrap"
                              onClick={() => {
                                headerChild(e.id);
                              }}
                            >
                              {e.title} &nbsp;
                              <span className={classes.active}>
                                {filerdatavalue.length}
                              </span>
                            </Typography>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.filterRight}>
                    {filterChild.map((val) => {
                      return (
                        <>
                          <FormControlLabel
                            key={val.id}
                            style={{ display: "block" }}
                            className={classes.filterdataChild}
                            control={<Checkbox checked={val.value} />}
                            label={val.label}
                          />
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container className={classes.btncontainer} spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contain"
                      fullWidth={true}
                      className={classes.cancelbtn}
                      onClick={drawerclose}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      className={classes.applybtn}
                    >
                      {t("Apply")}
                    </Button>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer open={drawer} onClose={drawerclose}>
                <Grid container>
                  <Grid item xs={12}>
                    <TitleBar
                      text={t("ExperiedAgreement")}
                      goBack={drawerclose}
                      clearFilter
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.filterleft}>
                    <div>
                      {filterData.map((e) => {
                        let filerdatavalue = e.values.filter((e) => {
                          return e.value === true;
                        });

                        return (
                          <>
                            <Typography
                              className={
                                e.id === filterActive
                                  ? classes.filterdataActive
                                  : classes.filterdata
                              }
                              key={e.id}
                              whiteSpace="nowrap"
                              onClick={() => {
                                headerChild(e.id);
                              }}
                            >
                              {e.title} &nbsp;
                              <span className={classes.active}>
                                {filerdatavalue.length}
                              </span>
                            </Typography>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.filterRight}>
                    {filterChild.map((val) => {
                      return (
                        <>
                          <FormControlLabel
                            key={val.id}
                            style={{ display: "block" }}
                            className={classes.filterdataChild}
                            control={<Checkbox checked={val.value} />}
                            label={val.label}
                          />
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container className={classes.btncontainer} spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contain"
                      fullWidth={true}
                      className={classes.cancelbtn}
                      onClick={drawerclose}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      className={classes.applybtn}
                    >
                      {t("Apply")}
                    </Button>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>
          </Container>
        </>
      )}
    </div>
  );
};
export default withNamespaces("experiedagreement")(ExperiedAgreement)