
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Password, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, FirebaseContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, Message, ValidateEmail } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ForgetPassword from "./forgetPassword";
import { silentOTP } from "./silentOTP";
import { TitleBarNew } from "../../components/titlebarNew";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { initital_screen_state } from "../../utils/login/utils";
import { loginUseStyles } from "./style";
import { getCurrentBuildNumber } from "../../utils/version";
import { ExploreIcon } from "./assets/exploreIcon";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%"
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "1.5rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    height: "48px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
  },
  img: {
    height: "8%",
    width: "28%",
  },
  forgetPassword: {
    float: "right",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    marginTop: 7,
    cursor: "pointer",
  },
  forgetPasswordTextStyle: {
    textAlign: "right",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",

  },
  contentBottom: {
    padding: "16px",
  },
  dialogStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  version: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
    color: "#091B29"
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "0px" : "11px 14px"),
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
}));


const LoginDetails = ({ t = () => false }) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const classes_1 = loginUseStyles({ size });
  const react_history = useHistory();
  const state = useLocation().state;
  const alert = React.useContext(AlertContext);
  const [decoded, setDecoded] = React.useState({
    decode: null,
    isDecode: false
  });
  const InitialState = {
    email: decoded?.isDecode > 0
      ? decoded?.decode?.email_id
      : "",
    password: "",
    error: {
      email: "",
      password: "",
    },
  };
  const [login, setLogin] = React.useState({ ...InitialState });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const search = useLocation().search;
  const firebaseContext = React.useContext(FirebaseContext);
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  const deviceToken = new URLSearchParams(search).get("deviceToken");
  const device = new URLSearchParams(search).get("device");
  const latitude = new URLSearchParams(search).get("latitude");

  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [disable, setDisable] = React.useState(false)
  const [app_version, set_app_version] = React.useState(null);
  const [regionAnchorEl, setRegionAnchorEl] = useState(null);
  const is_region_open = Boolean(regionAnchorEl);
  const [region_list, set_region_list] = useState({});
  useEffect(() => {
    getCurrentBuild();
    initialLoad();
    getRegionList();
    // eslint-disable-next-line
  }, []);

  const getCurrentBuild = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
        set_app_version(temp_app_version);
      } catch (error) {
        reject(error);
      }
    });
  }

  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let is_custom_domain = res?.data?.data?.is_custom_domain;
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [is_custom_domain && "is_custom_domain"]: is_custom_domain,
            [_?.explore_meta && "explore_meta"]: _?.explore_meta?.[0],
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }
  React.useEffect(() => {
    let alert_msg = localStorage?.getItem?.(LocalStorageKeys?.alert_msg)
    if (alert_msg?.length > 0) {
      alert.setSnack({
        ...alert, open: true, msg: t(alert_msg),
        severity: AlertProps?.severity?.info,
      });
      localStorage?.removeItem?.(LocalStorageKeys?.alert_msg);
    }

    if (localStorage.getItem("device_token")) {
    } else {
      firebaseContext.requestPermission();
    }

    if (device === "mobile") {
      localStorage.setItem("device_type", device === "mobile" ? "app" : "web")
    }

    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded({
        decode: jwt_decode(welcomePageAuthToken),
        isDecode: true
      })
      const email_id = jwt_decode(welcomePageAuthToken)
      setLogin({ ...login, email: email_id?.email_id })
    } else if (authToken !== null && authToken !== "") {
      react_history.push(Routes.dashboard);
    } else {
      // history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [react_history]);

  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = login.error;
    // if (decoded != null && decoded.email_id.length === 0) {
    //   isValid = false;
    //   error.email = Message.English.requiredMessage("Email");
    // }
    if (login?.email?.length === 0) {
      isValid = false;
      error.email = t("Email is required");
    }
    if (login?.password?.length === 0) {
      isValid = false;
      error.password = t("Password is required");
    }
    // if (
    //   ((decoded != null && decoded.email_id !== "") || login.email !== "") &&
    //   login.password !== ""
    // ) {
    //   isValid = true;
    // }
    setLogin({ ...login, error });
    return isValid;
  };
  // eslint-disable-next-line
  const onSendLoginBtnClicked = (e) => {
    e.preventDefault();
    let data = {
      //tenantId: "8e9c54d2-45ef-4fac-bb37-6bc7d35bb199",
      password: login.password,
    };
    if (isIamValideToLogin()) {
      // Check phone number

      //eslint-disable-next-line
      var isphone = /^\d+$/.test(login.email)

      // Check email id

      //eslint-disable-next-line
      var email = ValidateEmail(login.email)


      if (isphone) {
        data["mobile_no"] = login.email;
      } else if (email) {
        data["email_id"] = login.email;
      } else {
        data["ucid"] = login.email;
      }

      NetworkCall(
        `${config.auth_api_url}/auth/login`,
        NetWorkCallMethods.post,
        data,
        { "Referer": "https://resident.pms2.propgoto.com/" },
        false,
        false
      )
        .then((response) => {
          if (response?.status === 200) {
            const email_ID = jwt_decode(response?.data?.token)
            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
              react_history.push(
                Routes.otpPage + "?welcomeAuthToken=" + welcomePageAuthToken
              );

            } else {
              react_history.push(
                Routes.otpPage +
                "?data=" +
                email_ID?.email_id
              );
            }

            localStorage.setItem(LocalStorageKeys.authToken, response.data.token)
            localStorage?.setItem?.(LocalStorageKeys?.user_id, jwt_decode(response?.data?.token)?.id);
          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("InvalidCredientials"),
          });
        });
    } else {
      return false;
    }
  };

  const onDrawerClose = () => {
    setShowDrawer(false);
  };

  const setLocation = () => {


    if (latitude && device === "mobile") {
      localStorage.setItem("device", device);
      localStorage.setItem("location", JSON.stringify({
        latitude: latitude,
        longitude: new URLSearchParams(search).get("longitude"),
        address: new URLSearchParams(search).get("address"),
        city: new URLSearchParams(search).get("city"),
        code: new URLSearchParams(search).get("code"),
        country_name: new URLSearchParams(search).get("country_name"),
      }));

    }


  }


  const handleRegion = (e) => {
    setRegionAnchorEl(e.currentTarget);
  }

  const handleRegionClick = (_) => {
    setRegionAnchorEl(null);

    let temp_screen_state = {
      ...screen_state,
      [_?.id && "id"]: _?.id,
      [_?.region && "region"]: _?.region,
      [_?.login_logo && "logo"]: _?.login_logo,
      [_?.web_background && "web_background"]: _?.web_background,
      [_?.mobile_background && "mob_background"]: _?.mobile_background,
      [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
      [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
      [_?.flag && "flag"]: _?.flag,
      [_?.domain && "domain"]: _?.domain?.[0]?.domain,
      [_?.explore_meta && "explore_meta"]: _?.explore_meta?.[0],
    }

    set_screen_state(temp_screen_state);

    let link = `${temp_screen_state?.domain}${window.location.pathname}${window.location.search}`;
    window.open(link, "_self");

    alert.setSnack({
      ...alert, open: true,
      severity: AlertProps.severity.info,
      msg: `${t("Region Changed to")} ${temp_screen_state?.region} ${t("successfully.")}`,
    });
  }


  const handleGoBack = () => {
    react_history.goBack(-1);
  }

  const getRegionList = () => {
    NetworkCall(
      `${config.auth_api_url}${BackendRoutes?.login_domain_domain_list}`,
      NetWorkCallMethods.post, {}, false, false
    ).then((response) => {
      set_region_list(response?.data?.data?.domainList ?? []);
    }).catch((err) => {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    });
  };

  const handleImageOnLoad = () => {
    setIsBackgroundImageLoading(false);
  }

  const handleImageOnError = () => {
    set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
  }

  const handleExploreCard = () => {
    let finalUrl = screen_state?.explore_meta?.link ?? "";

    if (finalUrl && !finalUrl.startsWith("http://") && !finalUrl.startsWith("https://")) {
      finalUrl = "https://" + (finalUrl.startsWith("www.") ? finalUrl?.replace("www.", "") : finalUrl);
    }

    finalUrl && window.open(finalUrl, "_blank", "noreferrer");
  }

  return (
    <div>
      {is_loading
        ? <></>
        : <Container className={classes_1.mob_right_section_card} maxWidth="sm">
          <TitleBarNew is_back_button={[Routes?.welcomePage, Routes?.welcome]?.includes?.(state?.origin)}
            title={t("Log In")} goBack={handleGoBack} />
          <Grid container className={classes_1.mob_content}>
            <Grid item xs={12} className={classes_1.mob_body_items}
              sx={{ height: size?.height - 161, overflow: "overlay" }}>
              <Box className={classes_1.mob_background}>
                {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes_1.mob_background_image} />}
                <img className={classes_1.mob_background_image}
                  style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                  src={screen_state?.mob_background} alt=""
                  onLoad={handleImageOnLoad}
                  onError={handleImageOnError} />
              </Box>
              {Boolean(screen_state?.explore_meta?.is_active) &&
                <>
                  <Box height={"16px"} />
                  <Stack className={classes_1.exploreCard}
                    direction={"row"} columnGap={"10px"}
                    alignItems={"center"} justifyContent={"space-between"}
                    onClick={handleExploreCard}>
                    <Stack direction={"row"} columnGap={"10px"}
                      alignItems={"center"}>
                      <ExploreIcon />
                      <Typography className={classes_1.exploreInformation}>
                        {screen_state?.explore_meta?.information}
                      </Typography>
                    </Stack>
                    <Typography className={classes_1.exploreCTA}>
                      {screen_state?.explore_meta?.cta_text}
                    </Typography>
                  </Stack>
                </>}
              <Box height={"16px"} />
              <Stack direction={"row"} columnGap={"16px"}
                justifyContent={"space-between"} alignItems={"start"}>
                <Typography className={classes_1.web_title}>
                  {config?.app_x_build === "PG-TE-08" ? t("Login as Tenant") : t("Login as Resident")}
                </Typography>
                {!Boolean(screen_state?.is_custom_domain) &&
                  <Box className={classes_1.region_box} onClick={handleRegion}>
                    <img className={classes_1.region_flag} src={screen_state?.flag} alt="" />
                    {is_region_open
                      ? <KeyboardArrowUpRoundedIcon fontSize="small" color="#4E5A6B" />
                      : <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                    }
                  </Box>
                }
              </Stack>
              <Box height={"16px"} />
              <TextBox
                type="text"
                value={login?.email ?? ""}
                onChange={(e) => updateState("email", e.target.value)}
                label={Message.English.login.email.label}
                placeholder={Message.English.login.email.placeholder}
                labelColor={"#98A0AC"}
                isError={login.error.email.length > 0}
                errorMessage={login.error.email}
                isReadonly={decoded && decoded.email_id ? true : false}
                isRequired
                className={classes.textbox}
              />
              <Box height={"24px"} />
              <Password
                value={login?.password}
                onChange={(e) => updateState("password", e.target.value)}
                label={t("Password")}
                labelColor={"#98A0AC"}
                placeholder={t("Enter Password")}
                isError={login.error.password.length > 0}
                errorMessage={login.error.password}
                isRequired
              />
              <Box height={"8px"} />
              <Typography className={classes_1.web_forget_password}
                onClick={() => setShowDrawer(true)}>
                {t("Forgot password?")}
              </Typography>
            </Grid>
            <Grid item xs={12} alignSelf={"self-end"} className={classes_1.mob_bottom_items}>
              <Stack direction={"column"} alignItems={"start"}>
                <Button className={classes_1.mob_set_up_new_button} disabled={disable}
                  onClick={(e) => {
                    setDisable(true)
                    setLocation()
                    silentOTP(e, login, alert, react_history, isIamValideToLogin, setDisable, t, welcomePageAuthToken, deviceToken)
                  }}>
                  <Typography className={classes_1.mob_set_up_new_button_text}>
                    {t("Login")}
                  </Typography>
                </Button>
                <Box height={"16px"} />
                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                  justifyContent={"space-between"} alignItems={"center"}>
                  <img className={classes_1.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                  {app_version &&
                    <Typography className={classes_1.version_no}>
                      {`V ${app_version}`}
                    </Typography>
                  }
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>}

      {/* Region List */}
      <Menu
        id="region-menu_list" open={is_region_open}
        anchorEl={regionAnchorEl} elevation={0}
        className={classes_1.region_menu_list}
        onClose={() => setRegionAnchorEl(null)}
        MenuListProps={{ "aria-labelledby": "basic-button", }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
        transformOrigin={{ vertical: "top", horizontal: "right", }}>
        {region_list?.map?.((_) => {
          return (
            <MenuItem
              onClick={() => handleRegionClick(_)}
              className={_?.id === screen_state?.id
                ? classes_1.selected_region_menu_item
                : classes_1.unselected_region_menu_item}>
              <img className={classes_1.region_flag} src={_?.flag} alt="" />
              <Box width={"10px"} />
              <Typography className={_?.id === screen_state?.id
                ? classes_1.selected_region_name
                : classes_1.unselected_region_name}>
                {_?.region}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
      {/* Drawer */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          open={showDrawer}
          anchor="bottom"
          onClose={() => setShowDrawer(false)}
        >
          <Box
            display="flex"
            p={2}
            alignItems="center"
            className={classes.titleroot}
          >
            <Box flexGrow={1}>
              <Typography className={classes.bottomTitle}>
                {t("ForgetPassword")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                onClick={() => setShowDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <div className={classes.contentBottom}>
            <ForgetPassword onDrawerClose={onDrawerClose} />
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          className={classes.dialogStyle}
        >
          <Box
            display="flex"
            p={2}
            alignItems="center"
            className={classes.titleroot}
          >
            <Box flexGrow={1}>
              <Typography className={classes.bottomTitle}>
                {t("ForgetPassword")}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setShowDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <div className={classes.contentBottom}>


            <ForgetPassword onDrawerClose={onDrawerClose} />
          </div>

        </Dialog>
      </Hidden>
    </div>
  );
};
export default withNamespaces("welcomePage")(LoginDetails)